import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

// API Slice using Redux Toolkit Query
export const bannersApi = createApi({
  reducerPath: "bannersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://euro-soccer-dahboard.eurosoccer-uae.com/api/",
  }),
  endpoints: (builder) => ({
    fetchBanners: builder.query({
      query: (lang) => ({
        url: "banners",
        headers: {
          lang: lang,
        },
      }),
      staleTime: 1000 * 60 * 5,
    }),
  }),
});

// Export the hook generated by RTK Query
export const { useFetchBannersQuery } = bannersApi;

// Async thunk to fetch about-us data
export const fetchAboutUs = createAsyncThunk(
  "banners/fetchAboutUs",
  async (lang) => {
    const response = await axios.get(
      "https://euro-soccer-dahboard.eurosoccer-uae.com/api/about-us",
      {
        headers: {
          lang: lang,
        },
      }
    );
    return response?.data?.data;
  }
);

// Async thunk to fetch coaches data
export const fetchCoaches = createAsyncThunk(
  "banners/fetchCoaches",
  async (lang) => {
    const response = await axios.get(
      "https://euro-soccer-dahboard.eurosoccer-uae.com/api/coaches",
      {
        headers: {
          lang: lang,
        },
      }
    );
    return response?.data?.data?.coaches;
  }
);

// Slice
const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    aboutUs: null,
    coaches: [],
    loadingAboutUs: false,
    loadingCoaches: false,
    errorAboutUs: null,
    errorCoaches: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutUs.pending, (state) => {
        state.loadingAboutUs = true;
        state.errorAboutUs = null;
      })
      .addCase(fetchAboutUs.fulfilled, (state, action) => {
        state.loadingAboutUs = false;
        state.aboutUs = action.payload;
      })
      .addCase(fetchAboutUs.rejected, (state, action) => {
        state.loadingAboutUs = false;
        state.errorAboutUs = action.error.message;
      })
      .addCase(fetchCoaches.pending, (state) => {
        state.loadingCoaches = true;
        state.errorCoaches = null;
      })
      .addCase(fetchCoaches.fulfilled, (state, action) => {
        state.loadingCoaches = false;
        state.coaches = action.payload;
      })
      .addCase(fetchCoaches.rejected, (state, action) => {
        state.loadingCoaches = false;
        state.errorCoaches = action.error.message;
      });
  },
});

// Export the reducer to be used in the store
export default bannersSlice.reducer;
