import React, { createContext, useState } from "react";
import img1 from "../assets/images/bg1.webp";
import img2 from "../assets/images/bg2.webp";
import img3 from "../assets/images/bg3.webp";
import img4 from "../assets/images/bg4.webp";
import img5 from "../assets/images/bg5.webp";
import img6 from "../assets/images/bg6.webp";
const BacgroundContext = createContext();

const BackgroundProvider = ({ children }) => {
  const images = [img1, img2, img3, img4, img5, img6];
  const [activeImg, setActiveImg] = useState(img1);

  return (
    <BacgroundContext.Provider value={{ activeImg, setActiveImg, images }}>
      {children}
    </BacgroundContext.Provider>
  );
};

export { BackgroundProvider, BacgroundContext };
